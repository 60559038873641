import React from "react"
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../../components/layout"
import Spacer from "../../components/spacer"
import Feature from "../../components/feature"
import FlexLayout from "../../components/flex-layout"
import Tooltip from "../../components/tooltip"

import './index.less'

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>

      <table>
        <tr>
          <td><Img fixed={data.printLogo.childImageSharp.fixed} /></td>
          <td><h1>The Rail Ticket Fulfilment API</h1></td>
        </tr>
      </table>
      
      <p>
        <Link to="/smartix/" className="printLink">smartix</Link> is a new rail ticket fulfilment service. It is important to say from the outset 
        that it is not a <Tooltip>TIS</Tooltip>. A <Tooltip>TIS</Tooltip> (as defined by <Tooltip>RDG</Tooltip>) comprises of a Journey Planner, 
        Reservations, Fulfilment, Settlement, After Sales Service (Cancellations etc.) and User Interface. It does not have an internal Journey 
        Planner and it has no User Interface. It does, however, provide Reservations, Fulfilment, Settlement and After Sales functionality. 
      </p>

      <p>In other words, it does everything except plan journeys and provide a display for your users</p>

      <FlexLayout>

        <Feature image={data.jpImage.childImageSharp.fixed.src} title="Journey Planner" top="120" link="/smartix/journey-planner/">
          <p>We do not provide a Journey Planner</p>
          <p>You choose one that meets your needs</p>
        </Feature>

        <Feature image={data.resvImage.childImageSharp.fixed.src} title="Reservations" link="/smartix/reservations/">
          <p>We have an advanced seat reservation service optimised for split tickets and customer satisfaction</p>
        </Feature>        

        <Feature image={data.fulfilImage.childImageSharp.fixed.src} title="Fulfilment" top="120" link="/smartix/fulfilment/">
          <p>We create <Tooltip>eTickets</Tooltip> for you to deliver to your customers and paper tickets for collection at the station (<Tooltip>ToD</Tooltip>)</p>
        </Feature>        

        <Feature image={data.settleImage.childImageSharp.fixed.src} title="Settlement" link="/smartix/settlement/" top="50">
          <p>We provide settlement data (<Tooltip>SDCI+</Tooltip>) direct to <Tooltip>RDG</Tooltip> on your behalf</p>
        </Feature>        

        <Feature image={data.afterImage.childImageSharp.fixed.src} title="After Sales" top="170" link="/smartix/after-sales/">
          <p>We handle cancellations, changes and reporting back to <Tooltip>RDG</Tooltip></p>
        </Feature>        

        <Feature image={data.uiImage.childImageSharp.fixed.src} title="User Interface" link="/smartix/user-interface/" top="50">
          <p>We do not provide a user interface</p>
          <p>You will extend your existing <Tooltip>UI</Tooltip> applications</p>
        </Feature>        

      </FlexLayout>

    </content>

    <Spacer>
      <p>
        Why is <Link to="/smartix/" className="printLink">smartix</Link> unconventional?
      </p>
    </Spacer>

    <content>

      <blockquote>
        <span><Link to="/smartix/" className="printLink">smartix</Link> provides unique enhanced support for split ticket purchases.</span>
      </blockquote>

      <p>As we have already mentioned, <Link to="/smartix/" className="printLink">smartix</Link> is not a <Tooltip>TIS</Tooltip>. That in itself makes it 
      unique. <Link to="/smartix/" className="printLink">smartix</Link> is the first collection of micro services to be accredited by <Tooltip>RDG</Tooltip> for 
      ticket fulfilment. You choose how you combine those services to create a bespoke service for your customers.</p>

      <p>We do not prescribe a journey planner. <Link to="/smartix/" className="printLink">smartix</Link> is capable of working with any, 
      so you are free to use whichever one best suits your needs.</p>

      <p><Tooltip>RDG</Tooltip> provide a seat selection system called <Tooltip>NRS</Tooltip> that is used by every <Tooltip>TIS</Tooltip>. It is seriously flawed, 
      rarely chooses the best seats and is hostile towards split ticket bookings. <Link to="/smartix/" className="printLink">smartix</Link> is the only <Tooltip>TIS</Tooltip> not to 
      use <Tooltip>NRS</Tooltip> for seat selection. We have our own, greatly enhanced system that provides a range of options, including interactive seat maps, 
      to ensure your customers are allocated the best possible seats every time.</p>

      <p><Link to="/smartix/" className="printLink">smartix</Link> also has a unique architecture that enables us to operate at a lower cost
      than a traditional <Tooltip>TIS</Tooltip>. We pass those savings onto you, which makes <Link to="/smartix/" className="printLink">smartix</Link> the most
      cost-effective service you will find.</p>

    </content>

    <Spacer>
      <p>
        signature <Link to="/smartix/" className="printLink">smartix</Link> features
      </p>
    </Spacer>

    <content>
      <ul>
        <FlexLayout>
          <li><Link to="/smartix/reservations/#seating-options">Live seating options</Link></li>
          <li><Link to="/smartix/reservations/#advanced-selection">Enhanced seating data</Link></li>
          <li><Link to="/smartix/reservations/#split-tickets">Split ticket seating support</Link></li>
          <li><Link to="/smartix/reservations/#seat-maps">Interactive seat maps</Link></li>
          <li><Link to="/smartix/fulfilment/">Unique eTickets</Link></li>
          <li><Link to="/smartix/fulfilment/">Mixed fulfilment</Link></li>
          <li><Link to="/smartix/data-management/">Rail data management</Link></li>
          <li><Link to="/smartix/architecture/#error-tracking">Error tracking</Link></li>
          <li><Link to="/smartix/architecture/#concurrent-versioning">Multi-version</Link></li>
          <li><Link to="/smartix/after-sales/">Refunds (After Sales)</Link></li>
        </FlexLayout>
      </ul>
    </content>

  </Layout>
)

export const query = graphql`
  query {
    printLogo: file(relativePath: {eq: "smartix-blue.png"}) {
      childImageSharp {
        fixed(width: 122) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jpImage: file(relativePath: {eq: "smartix/journey-planner.png"}) {
      childImageSharp {
        fixed(width: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    resvImage: file(relativePath: {eq: "smartix/reservations.png"}) {
      childImageSharp {
        fixed(width: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fulfilImage: file(relativePath: {eq: "smartix/fulfilment.png"}) {
      childImageSharp {
        fixed(width: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    settleImage: file(relativePath: {eq: "smartix/settlement.png"}) {
      childImageSharp {
        fixed(width: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    afterImage: file(relativePath: {eq: "smartix/after-sales.png"}) {
      childImageSharp {
        fixed(width: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    uiImage: file(relativePath: {eq: "smartix/user-interface.png"}) {
      childImageSharp {
        fixed(width: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }    
  } 
`

export default Page